<template>
  <div class="message-manager">
    <en-table-layout :table-data="pageData.data">
      <template slot="toolbar">
        <div class="toolbar-title col-auto">

        </div>
        <div class="col"></div>
        <div class="col-auto">
          <el-button size="small" class="gobuy" @click="czShow" type="primary">申请短信签名</el-button>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column prop="sign_name" label="签名名称"></el-table-column>
        <el-table-column prop="create_time" label="申请时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column prop="audit_time" label="处理时间" width="160">
          <template slot-scope="scope">
            {{ scope.row.audit_time | unixToDate('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{ statusMap[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column
          prop="refuse_reason"
          show-overflow-tooltip
          label="通过/驳回原因"
        ></el-table-column>
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              v-show="scope.row.status === 2 || scope.row.status === 5"
              @click="handleEdit(scope.row)"
            >
              修改
            </el-button>
          </template>
        </el-table-column>
      </template>

      <el-pagination
        slot="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="params.page_size"
        :page-sizes="MixinPageSizes"
        :layout="MixinTableLayout"
        background
        :total="total"
      ></el-pagination>
    </en-table-layout>

    <sqMsg
      ref="applyMsg"
      :inputData="inputData"
      :fileList1="fileList1"
      :fileList2="fileList2"
      @refresh="refreshData"
    />
  </div>
</template>

<script>
import sqMsg from './sqmsg';
import * as API_account from '@/api/account';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  components: {
    EnTableLayout,
    sqMsg
  },
  mounted() {
    this.GET_ManageList();
    if (this.$route.query.num === '1') {
      this.czShow();
    }
  },
  data() {
    return {
      currentPage3: 1,
      total: 0,
      params: {
        page_no: 1,
        page_size: 20,
      },
      pageData: {data: []},
      statusMap: [
        null,
        '审核中',
        '平台审核拒绝',
        '平台审核通过',
        '短信平台待审核',
        '短信平台审核拒绝',
        '短信平台审核通过',
      ],
      inputData: {},
      fileList1: [],
      fileList2: [],
    };
  },
  methods: {
    // 修改
    handleEdit(row) {
      this.$refs.applyMsg.show();
      this.inputData = this.MixinClone(row);
      this.fileList1 = [{url: row.empower_img}];
      this.fileList2 = [{url: row.licence_img}];
    },
    GET_ManageList() {
      let params = this.params;
      API_account.ManageList(params).then((res) => {
        this.total = res.data_total;
        this.pageData = res;
      });
    },
    handleSizeChange(val) {
      this.params.page_size = val;
      this.GET_ManageList();
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.params.page_no = val;
      this.GET_ManageList();
      console.log(`当前页: ${val}`);
    },
    czShow() {
      this.inputData = {}
      this.fileList1 = [];
      this.fileList2 = [];
      this.$refs.applyMsg.show();
    },
    refreshData() {
      this.fileList1 = [];
      this.fileList2 = [];
      this.GET_ManageList();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
